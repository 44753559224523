import React from "react";

function SortByIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#E8E5FF"
        fillOpacity="0.5"
        d="M18 4.906a.818.818 0 01-.819.818H9a.818.818 0 110-1.636h8.182a.818.818 0 01.818.818zm-3.273 3.273H8.999a.818.818 0 100 1.636h5.728a.818.818 0 000-1.636zm-3.273 4.09H8.999a.818.818 0 100 1.637h2.455a.819.819 0 000-1.636zm-5.384-.99l-1.162.99V4.089a.818.818 0 00-1.636 0v8.181l-1.162-.99a.695.695 0 10-.908 1.048l2.455 2.11c.025.02.052.037.081.05.039.027.08.048.123.065h.507a.583.583 0 00.123-.065.435.435 0 00.082-.05l2.454-2.11a.695.695 0 10-.908-1.047H6.07z"
      ></path>
    </svg>
  );
}

export default SortByIcon;
