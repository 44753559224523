import {
  useEffect,
  useRef,
  useState,
  React,
  useCallback,
  useMemo,
} from "react";
import useDetectOutside from "../hooks/useDetectOutside";
import st from "../styles/items/DropDown.module.scss";
import Button from "./Button";
import { usePopper } from "react-popper";
import ReactDOM from "react-dom";
import { mergeClasses } from "@lib/tools/helpers";

const isClient = typeof window !== "undefined";

export default function DropDown({
  toggleButton,
  children,
  text = " ",
  ValueIcon = null,
  classType,
  dropDownStyle = false,
  icon2 = false,
  extraContent = false,
  closeOnClick = true,
  manualClose = null,
  manualOpen = null,
  placement = "bottom-start",
  fallbackPlacements = ["top-start"],
  dropdownTracking = false,
  portalId = null, // New prop for portal ID
  strategy = "fixed",
  sameWidth = false,
  showDefaultIcon = true,
  hasGradient = true,
  customOffSet = 0,
  leftPadding = null,
  customClass = null,
  onOpenChange = null,
}) {
  const [active, setActiveState] = useState(false);
  const [render, setRender] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: strategy,
    placement: placement,
    modifiers: [
      {
        name: "flip",
        enabled: true,
        options: {
          fallbackPlacements: fallbackPlacements,
        },
      },
      {
        name: "offset",
        options: { offset: [customOffSet, 10] },
      },
    ],
  });
  const contentToggle = useRef(null);

  //super confusing but it works
  //lets it get a transition in
  const setActive = useCallback(
    (value) => {
      let item = value;
      let renderTime = 200;
      let activeTime = 100;

      if (value === "toggle") {
        item = (item) => !item;
      }

      const timer2 = setTimeout(
        () => {
          setActiveState(item);
        },
        !active ? activeTime : 0,
      );

      const timer = setTimeout(
        () => {
          setRender(item);
        },
        active ? renderTime : 0,
      );

      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    },
    [active],
  );

  //Lets parents components track when the dropdown is open
  useEffect(() => {
    if (dropdownTracking) {
      dropdownTracking(render);
    }
  }, [dropdownTracking, render]);

  const { ref: refClose } = useDetectOutside(
    true,
    () => {
      setActive(false);
    },
    contentToggle,
  );

  // Manual closing from outside sources
  useEffect(() => {
    if (manualClose !== null) {
      setActive(false);
    }
  }, [manualClose, setActive]);

  // Manual opening from outside sources
  useEffect(() => {
    if (manualOpen !== null) {
      setActive(true);
    }
  }, [manualOpen, setActive]);

  const [dropdownWidth, setDropdownWidth] = useState(null);

  useEffect(() => {
    if (contentToggle.current && sameWidth) {
      setDropdownWidth(contentToggle.current.offsetWidth);
    }
  }, [active, sameWidth]);

  const dropdownContent = useMemo(
    () => (
      <div
        ref={setPopperElement}
        style={{
          ...styles.popper,
          ...(dropdownWidth ? { width: dropdownWidth } : {}),
          ...(leftPadding ? { left: leftPadding } : {}),
        }}
        {...attributes.popper}
        onClick={() => {
          if (closeOnClick) {
            setActive(false);
          }
        }}
        data-menu="dropdown"
        {...mergeClasses(
          "drop-down-class",
          st["drop-down-container"],
          st[dropDownStyle],
          active && st["active"],
          customClass && customClass,
        )}
      >
        <div>
          <div ref={refClose}>{children}</div>
        </div>
      </div>
    ),
    [
      active,
      attributes.popper,
      children,
      closeOnClick,
      dropDownStyle,
      dropdownWidth,
      leftPadding,
      refClose,
      setActive,
      styles.popper,
    ],
  );

  useEffect(() => {
    if (onOpenChange) {
      onOpenChange(active);
    }
  }, [active, onOpenChange]);

  return (
    <>
      {/* Toggle button  */}
      <div ref={contentToggle}>
        {!toggleButton ? (
          <Button
            forwardedRef={setReferenceElement}
            text={text}
            method={setActive}
            param={!active}
            active={active}
            classType={classType}
            icon={showDefaultIcon ? "arrow" : false}
            icon2={icon2}
            extraContent={extraContent}
            ValueIcon={ValueIcon}
            hasGradient={hasGradient}
          />
        ) : (
          <div
            ref={setReferenceElement}
            onClick={() => {
              setActive((item) => !item);
            }}
            className={st["toggle-container"]}
          >
            {toggleButton}
          </div>
        )}
      </div>
      {/* dropdown content */}
      {render
        ? portalId && isClient
          ? ReactDOM.createPortal(
              dropdownContent,
              document.getElementById(portalId),
            )
          : dropdownContent
        : null}
    </>
  );
}
