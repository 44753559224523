import React from "react";

function PopularIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#E8E5FF"
        d="M13.497 6.626c-2.65-2.416-2.94-4.13-2.748-5.115.058-.299-.291-.5-.533-.315C9.26 1.92 8.026 3.119 7.25 4.9a8.569 8.569 0 00-.631 2.233c-.091.645-.9.922-1.316.421A3.282 3.282 0 014.93 7a3.184 3.184 0 01-.372-1.286c-.022-.321-.427-.44-.608-.173-.745 1.094-1.708 3.033-1.285 5.368.58 3.19 3.554 5.578 6.012 5.909-1.346-.322-2.291-1.982-2.383-3.37-.192-2.87 3.24-5.023 3.867-4.626.311.198-.208.932.224 1.964.474 1.134 1.467 1.118 1.785 1.92.517 1.299-1.014 4.058-2.918 4.16 5.056.138 8.553-6.312 4.246-10.24z"
      />
    </svg>
  );
}

export default PopularIcon;
