import React from "react";

function RandomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#E8E5FF"
        d="M17.797 13.068l-3.375-2.813a.564.564 0 00-.922.432v1.688h-.942c-.78 0-1.494-.396-1.908-1.057L7.008 5.49a4.473 4.473 0 00-3.816-2.115H0v2.25h3.192c.78 0 1.494.396 1.908 1.058l3.642 5.827a4.476 4.476 0 003.816 2.115h.942v1.688a.563.563 0 00.922.431l3.375-2.812a.56.56 0 000-.864z"
      />
      <path
        fill="#E8E5FF"
        d="M17.797 4.067l-3.375-2.812a.563.563 0 00-.922.432v1.687h-.942c-1.562 0-2.989.791-3.816 2.115l-.203.326 1.326 2.123.785-1.256a2.239 2.239 0 011.908-1.058h.942v1.688a.562.562 0 00.922.432l3.375-2.813a.56.56 0 000-.864zM5.885 10.06L5.1 11.317a2.24 2.24 0 01-1.91 1.057H0v2.25h3.192c1.561 0 2.988-.79 3.816-2.115l.203-.326-1.326-2.123z"
      />
    </svg>
  );
}

export default RandomIcon;
