import st from '../styles/items/CheckBox.module.scss'

export default function CheckBox({
    isChecked,
    readOnly = false,
    ...props
}) {
    return (
        <input type='checkbox' className={st['custom-checkbox']} checked={isChecked} {...props} readOnly={readOnly}/>
    )
}