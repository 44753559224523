import React, { useRef, useState, useCallback, useEffect } from 'react'
import st from '../styles/items/ScrollBar.module.scss'

export default function ScrollBar({
    children,
    maxHeight = 350,
    ...props
}){

    const contentRef = useRef(null);
    const scrollTrackRef = useRef(null);
    const scrollThumbRef = useRef(null);
    const observer = useRef(null);

    const [thumbHeight, setThumbHeight] = useState(20);
    const [scrollStartPosition, setScrollStartPosition] = useState(null);
    const [initialScrollTop, setInitialScrollTop] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [hasScroll, setHasScroll] = useState(true);
    const handleResize = (ref, trackSize) => {
        const { clientHeight, scrollHeight } = ref;
        setThumbHeight(Math.max((clientHeight / scrollHeight) * trackSize, 20));
    }
 
    const handleTrackClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            const { current: trackCurrent } = scrollTrackRef;
            const { current: contentCurrent } = contentRef;
            if (trackCurrent && contentCurrent) {
            const { clientY } = e;
            const target = e.target;
            const rect = target.getBoundingClientRect();
            const trackTop = rect.top;
            const thumbOffset = -(thumbHeight / 2);
            const clickRatio =
                (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
            const scrollAmount = Math.floor(
                clickRatio * contentCurrent.scrollHeight
            );
            contentCurrent.scrollTo({
                top: scrollAmount,
                behavior: 'smooth',
            });
            }
        },
        [thumbHeight]
    );

    const handleThumbPosition = useCallback(() => {
        if (
          !contentRef.current ||
          !scrollTrackRef.current ||
          !scrollThumbRef.current
        ) {
          return;
        }
        const { scrollTop: contentTop, scrollHeight: contentHeight } =
          contentRef.current;
        const { clientHeight: trackHeight } = scrollTrackRef.current;
        let newTop = (+contentTop / +contentHeight) * trackHeight;
        newTop = Math.min(newTop, trackHeight - thumbHeight);
        const thumb = scrollThumbRef.current;
        thumb.style.top = `${newTop}px`;
      }, []);

      const handleThumbMousedown = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setScrollStartPosition(e.clientY);
        if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop);
        setIsDragging(true);
      }, []);

    const handleThumbMouseup = useCallback(
    (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isDragging) {
        setIsDragging(false);
        }
    },
    [isDragging]
    );

    const handleThumbMousemove = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isDragging) {
            const {
                scrollHeight: contentScrollHeight,
                offsetHeight: contentOffsetHeight,
            } = contentRef.current;

            const deltaY =
                (e.clientY - scrollStartPosition) *
                (contentOffsetHeight / thumbHeight);
            const newScrollTop = Math.min(
                initialScrollTop + deltaY,
                contentScrollHeight - contentOffsetHeight
            );

            contentRef.current.scrollTop = newScrollTop;
            }
        },
        [isDragging, scrollStartPosition, thumbHeight]
    );

    // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
    useEffect(() => {
        if (contentRef.current && scrollTrackRef.current) {
        const ref = contentRef.current;
        const { clientHeight: trackSize } = scrollTrackRef.current;
        observer.current = new ResizeObserver(() => {
            if (ref.clientHeight < maxHeight)
                setHasScroll(false);
            else
                setHasScroll(true);
            handleResize(ref, trackSize);
        });
        observer.current.observe(ref);
        ref.addEventListener('scroll', handleThumbPosition);
        return () => {
            observer.current?.unobserve(ref);
            ref.removeEventListener('scroll', handleThumbPosition);
        };
        }
    }, []);
    
    // Listen for mouse events to handle scrolling by dragging the thumb
    useEffect(() => {
        document.addEventListener('mousemove', handleThumbMousemove);
        document.addEventListener('mouseup', handleThumbMouseup);
        document.addEventListener('mouseleave', handleThumbMouseup);
        return () => {
        document.removeEventListener('mousemove', handleThumbMousemove);
        document.removeEventListener('mouseup', handleThumbMouseup);
        document.removeEventListener('mouseleave', handleThumbMouseup);
        };
    }, [handleThumbMousemove, handleThumbMouseup]);
    
    return (
        <div className={st["custom-scrollbar-container"]}>
            <div ref={contentRef} className={st["custom-scrollbar-content"]}  style={{maxHeight: `${maxHeight}px`}}>
                {children}
            </div>
            {hasScroll && <div className={`${st["custom-scrollbar-scrollbar"]}`} style={{height: `${maxHeight-30}px`}}>
                <div className={st["custom-scrollbar-track-and-thumb"]}>
                    <div
                        className={st["custom-scrollbar-track"]}
                        ref={scrollTrackRef}
                        onClick={handleTrackClick}
                        style={{ cursor: isDragging && 'grabbing' }}
                    ></div>
                    <div
                        className={st["custom-scrollbar-thumb"]}
                        ref={scrollThumbRef}
                        onMouseDown={handleThumbMousedown}
                        style={{
                        height: `${thumbHeight}px`,
                        cursor: isDragging ? 'grabbing' : 'grab',
                        }}
                    ></div>
                </div>
            </div>
            }
        </div>
    )
}