export default function Close(props) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 6L1 1M6 6L11 11M6 6L11 1M6 6L1 11"
        stroke={props.fill || "#EEE"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill}
      />
    </svg>
  );
}
